import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, mergeMap } from 'rxjs';

import { FirebaseAuthenticationService } from '../services/firebase/firebase-authentication/firebase-authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: FirebaseAuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.authService.currentUser$.pipe(
      mergeMap(user => {
        if (user) {
          return from(
            this.authService.getIdToken({ forceRefresh: false }),
          ).pipe(
            mergeMap(data => {
              if (data?.token) {
                const clonedRequest = req.clone({
                  headers: req.headers.set(
                    'Authorization',
                    `Bearer ${data.token}`,
                  ),
                });
                return next.handle(clonedRequest);
              }
              return next.handle(req);
            }),
          );
        } else {
          return next.handle(req);
        }
      }),
    );
  }
}
