import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceRequestModel } from '@app/core/models';
import { ServiceRequestsRepository } from '@app/store';
import { trackRequestResult } from '@ngneat/elf-requests';

import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable()
export class ServiceRequestService {
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _serviceRequestsRepo: ServiceRequestsRepository,
  ) {}

  /**
   * Retrieves the user roles from the server.
   * @returns An observable that emits the user roles.
   */
  getAll(): Observable<ServiceRequestModel[]> {
    return this._http
      .get<
        ServiceRequestModel[]
      >(`${environment.api.serverUrl}/service-requests`)
      .pipe(
        tap(serviceRequests => {
          this._serviceRequestsRepo.updateServiceRequests(serviceRequests);
        }),
        trackRequestResult(['serviceRequests'], {skipCache: true}),
      );
  }

  editDate(
    serviceRequestId: string,
    date: {
      from: Date;
      to: Date;
    },
  ): Observable<ServiceRequestModel> {
    return this._http
      .patch<ServiceRequestModel>(
        `${environment.api.serverUrl}/service-requests/${serviceRequestId}/date`,
        { date },
      )
      .pipe(
        tap(serviceRequest => {
          this._serviceRequestsRepo.updateServiceRequest(serviceRequest);
        }),
      );
  }
}
