import { inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  UrlSegment,
} from '@angular/router';

import { BookingService, CategoryService, FirebaseAuthenticationService, ServiceService } from '@app/core/services';
import { ServiceRequestService } from '@app/core/services/backend/service-request.service';
import { BookingRepository, ServiceRequestsRepository } from '@app/store';


export const loadDataGuard: CanMatchFn = async (
  route: Route,
  segments: UrlSegment[],
) => {
  const bookingRepo = inject(BookingRepository);
  const bookingService = inject(BookingService);
  const serviceRequestRepo = inject(ServiceRequestsRepository);
  const serviceService = inject(ServiceService);
  const serviceRequestService = inject(ServiceRequestService);
  const categoryService = inject(CategoryService);
  const firebaseAuthenticationService = inject(FirebaseAuthenticationService);
  console.log(route, segments)
  const user = await firebaseAuthenticationService.getCurrentUser();
    if (user) {
        bookingRepo.activeBooking$.subscribe(booking => {
            if (!booking) {
              bookingService.getAll().subscribe(bookings => {
                  bookingRepo.activeBooking$.subscribe(booking => {
                      if (booking) {
                          serviceService.getAllByProperty(booking.property._id).subscribe();
                          serviceRequestService.getAll().subscribe(sr => {
                            serviceRequestRepo.upcomingReservations(booking._id);
                          });
                      }
                    });
              })
              categoryService.getAll().subscribe()
            }
          })
          return true;
    } else {
        return false;
    }
  }
