import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToNumber',
  standalone: true,
})
export class ConvertToNumberPipe implements PipeTransform {
  transform(value: any): number {
    if (!value || value < 0) return 0;

    return Number(value);
  }
}
