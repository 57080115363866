import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingModel } from '@app/core/models';
import { BookingRepository } from '@app/store/booking.repository';
import { trackRequestResult } from '@ngneat/elf-requests';
import { from, Observable, switchMap, tap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { environment } from 'src/environments/environment';
import { FirebaseAuthenticationService } from '../firebase/firebase-authentication/firebase-authentication.service';
import { RouterService } from '../router/router.service';

/**
 * Service responsible for handling bookings functionality.
 */
@Injectable()
export class BookingService {
  constructor(
    private _http: HttpClient,
    private _bookingRepo: BookingRepository,
    private _routerService: RouterService,
    private authService: FirebaseAuthenticationService,
  ) {}

  /**
   * Retrieves the user roles from the server.
   * @returns An observable that emits the user roles.
   */
  getAll(): Observable<BookingModel[]> {
    // Correctly await the token before making the request
    return from(this.authService.getIdToken({ forceRefresh: false })).pipe(
      switchMap(data => {
        // Use the Headers constructor correctly and include the token
        const headers = new Headers({
          Authorization: `Bearer ${data.token}`,
        });

        return fromFetch<BookingModel[]>(
          `${environment.api.serverUrl}/bookings`,
          {
            headers: headers,
            selector: response => response.json(),
          },
        );
      }),
      tap(bookings => {
        const availableBookings = bookings.filter(
          booking => new Date(booking.end) >= new Date(),
        );
        if (availableBookings && availableBookings.length) {
          this._bookingRepo.updateBookings(availableBookings);
        } else {
          console.log('redirect, no bookings');
          this._routerService.navigateToNoBookingsPage();
        }
      }),
      trackRequestResult(['bookings']),
    );
  }
}
