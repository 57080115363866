import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BookingModel, BookingPostModel } from '@app/core/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class PropertyService {
  constructor(
    private _router: Router,
    private _http: HttpClient,
  ) {}

  /**
   * Retrieves a property by its ID.
   * @param propertyId - The ID of the property to retrieve.
   * @returns An Observable that emits an array of properties.
   */
  get(propertyId: string): Observable<any[]> {
    return this._http.get<any[]>(
      `${environment.api.serverUrl}/properties/${propertyId}`,
    );
  }

  createBooking(data: BookingPostModel): Observable<BookingModel> {
    return this._http.post<BookingModel>(
      `${environment.api.serverUrl}/properties/${data.propertyId}/bookings`,
      { ...data.booking },
    );
  }
}
