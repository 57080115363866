// @ts-nocheck

import {
  AfterContentInit,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import toPx from 'to-px';
interface SafeAreaInterface {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Directive({
  selector: 'ion-header[parallax]',
  standalone: true,
})
export class ParallaxDirective implements AfterContentInit, OnDestroy {
  @Input() imageUrl: string = '';
  @Input() color: string = '';
  @Input() height: number = 300;
  @Input() bgPosition: 'top' | 'center' | 'bottom' = 'top';
  @Input() borderRadius: boolean = true;

  imageOverlay!: HTMLElement;
  private toolbarBackground!: HTMLElement;
  private innerScroll!: HTMLElement;
  private originalToolbarHeight = 0;
  private ticking = false;
  private toolbarContainer!: HTMLDivElement;

  @ContentChild(IonTitle, { static: false }) ionTitle: IonTitle & {
    el: HTMLIonTitleElement;
  };
  @ContentChild(IonBackButton, { static: false })
  ionBackButton: IonBackButton & {
    el: HTMLIonTitleElement;
  };
  @ContentChild(IonToolbar, { static: false }) ionToolbar: IonToolbar & {
    el: HTMLIonToolbarElement;
  };
  @ContentChildren(IonButtons) ionButtons: QueryList<
    IonButtons & { el: HTMLElement }
  >;
  public safeAreaInsets: SafeAreaInterface = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };
  constructor(
    private headerRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (Capacitor.isNativePlatform()) {
      SafeArea.getSafeAreaInsets().then((data: SafeAreaInsets) => {
        if (data && data?.insets) this.safeAreaInsets = data.insets;
      });
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      try {
        if (this.initElements()) {
          this.setupContentPadding();
          this.setupImageOverlay();
          this.setupPointerEventsForButtons();
          this.setupEvents();
          this.updateProgress();
        }
      } catch (e) {
        this.ngAfterContentInit();
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.imageUrl?.currentValue !== changes?.imageUrl?.previousValue) {
      if (this.imageOverlay) {
        this.renderer.setStyle(
          this.imageOverlay,
          'background-image',
          `url(${this.imageUrl || ''})`,
        );
      }
    }
  }

  private get header() {
    return this.headerRef.nativeElement;
  }

  /**
   * Return the value of the input parameter `height` as a string with units.
   * If no units were provided, it will default to 'px'.
   */
  getMaxHeightWithUnits() {
    return !isNaN(+this.height) || typeof this.height === 'number'
      ? this.height + 'px'
      : this.height;
  }

  getMaxHeightInPx() {
    return toPx(this.getMaxHeightWithUnits());
  }

  private initElements() {
    if (!this.ionToolbar) {
      console.error('A <ion-toolbar> element is needed inside <ion-header>');
      return false;
    }

    const parentElement = this.header.parentElement;
    const ionContent = parentElement.querySelector('ion-content');

    if (!ionContent) {
      console.error('A <ion-content> element is needed');
      return false;
    }

    this.innerScroll = ionContent.shadowRoot.querySelector(
      '.inner-scroll',
    ) as HTMLElement;

    this.originalToolbarHeight = this.ionToolbar.el.offsetHeight;

    if (this.ionBackButton) {
      const ionBackButton =
        this.ionBackButton.el.shadowRoot.querySelector('.button-native');
      if (ionBackButton) {
        this.renderer.setStyle(ionBackButton, 'color', 'white');
        this.renderer.setStyle(
          ionBackButton,
          'text-shadow',
          '0px 0px 10px rgba(0,0,0, 1)',
        );
      }
    }

    if (this.ionTitle.el) {
      this.renderer.setStyle(this.ionTitle.el, 'max-height', '44px');
      this.renderer.setStyle(this.ionTitle.el, 'color', 'white');
      this.renderer.setStyle(
        this.ionTitle.el,
        'text-shadow',
        '0px 0px 10px rgba(0,0,0, 1)',
      );
    }

    this.toolbarContainer =
      this.ionToolbar.el.shadowRoot.querySelector('.toolbar-container');

    this.toolbarBackground = this.ionToolbar.el.shadowRoot.querySelector(
      '.toolbar-background',
    );

    this.color =
      this.color ||
      window.getComputedStyle(this.toolbarBackground).backgroundColor;
    this.renderer.setStyle(this.toolbarContainer, 'align-items', 'baseline');
    /* this.renderer.setStyle(
      this.toolbarContainer,
      'box-shadow',
      'inset 0 0 0 1000pt rgba(0, 0, 0, 0.3)'
    ); */
    return true;
  }

  private setupPointerEventsForButtons() {
    this.renderer.setStyle(this.header, 'pointer-events', 'none');
    this.ionToolbar.el
      .querySelectorAll('ion-buttons')
      .forEach(item => this.renderer.setStyle(item, 'pointer-events', 'all'));
  }

  private setupContentPadding() {
    const parentElement = this.header.parentElement;
    const ionContent = parentElement.querySelector('ion-content');
    const mainContent = ionContent.shadowRoot.querySelector('.inner-scroll');
    const { paddingTop } = window.getComputedStyle(mainContent);
    const contentPaddingPx = toPx(paddingTop);
    const coverHeightPx = this.getMaxHeightInPx();
    this.renderer.setStyle(this.header, 'position', 'absolute');
    this.renderer.setStyle(
      this.innerScroll,
      'padding-top',
      `${contentPaddingPx + coverHeightPx + this.safeAreaInsets.top}px`,
    );
  }

  private setupImageOverlay() {
    if (this.borderRadius) {
      this.setBorderRadius();
    }
    this.imageOverlay = this.renderer.createElement('div');
    this.renderer.addClass(this.imageOverlay, 'image-overlay');

    this.renderer.setStyle(this.imageOverlay, 'filter', 'brightness(60%)');
    this.renderer.setStyle(
      this.imageOverlay,
      '-webkit-filter',
      'brightness(60%)',
    );
    this.renderer.setStyle(this.imageOverlay, '-moz-filter', 'brightness(60%)');

    this.renderer.setStyle(this.imageOverlay, 'background-color', this.color);

    this.renderer.setStyle(
      this.imageOverlay,
      'background-image',
      `url(${this.imageUrl || ''})`,
    );

    this.renderer.setStyle(this.imageOverlay, 'height', `100%`);
    this.renderer.setStyle(this.imageOverlay, 'width', '100%');
    this.renderer.setStyle(this.imageOverlay, 'background-size', 'cover');
    this.renderer.setStyle(
      this.imageOverlay,
      'background-position',
      this.bgPosition,
    );

    this.renderer.setStyle(
      this.imageOverlay,
      'padding-top',
      `${this.safeAreaInsets.top}px`,
    );

    this.toolbarBackground.appendChild(this.imageOverlay);
  }

  private setupEvents() {
    this.innerScroll.addEventListener('scroll', _event => {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.updateProgress();
          this.ticking = false;
        });
      }
      this.ticking = true;
    });
  }

  // Border radius
  setBorderRadius(progress?: number = 0) {
    const borderRadius = Math.round(40 * (1 - progress));
    this.renderer.setStyle(
      this.toolbarBackground,
      '-webkit-border-bottom-right-radius',
      `${borderRadius}px`,
    );
    this.renderer.setStyle(
      this.toolbarBackground,
      '-webkit-border-bottom-left-radius',
      `${borderRadius}px`,
    );
    this.renderer.setStyle(
      this.toolbarBackground,
      '-moz-border-radius-bottomright',
      `${borderRadius}px`,
    );
    this.renderer.setStyle(
      this.toolbarBackground,
      '-moz-border-radius-bottomleft',
      `${borderRadius}px`,
    );
    this.renderer.setStyle(
      this.toolbarBackground,
      'border-bottom-right-radius',
      `${borderRadius}px`,
    );
    this.renderer.setStyle(
      this.toolbarBackground,
      'border-bottom-left-radius',
      `${borderRadius}px`,
    );
  }

  /** Update the parallax effect as per the current scroll of the ion-content */
  updateProgress() {
    const h = this.getMaxHeightInPx();
    const progress = this.calcProgress(this.innerScroll, h);
    this.progressLayerHeight(progress);
    this.progressLayerOpacity(progress);

    if (this.borderRadius) this.setBorderRadius(progress);
  }

  progressLayerHeight(progress: number) {
    const h = Math.max(
      this.getMaxHeightInPx() * (1 - progress),
      this.originalToolbarHeight,
    );
    this.renderer.setStyle(this.toolbarContainer, 'height', `${h}px`);
    this.renderer.setStyle(this.imageOverlay, 'height', `${h}px`);
  }

  progressLayerOpacity(progress: number) {
    const op = 1 - progress;
    this.renderer.setStyle(this.imageOverlay, 'opacity', op);
    // this.renderer.setStyle(this.toolbarContainer, 'opacity', progress);
  }

  private calcProgress(scrollingElement: HTMLElement, maxHeight: number) {
    const scroll = +scrollingElement.scrollTop;
    const progress = Math.min(1, Math.max(0, scroll / maxHeight));
    return progress;
  }

  ngOnDestroy() {
    console.log('Directive is being destroyed');
    // Perform cleanup actions here
  }
}
