import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import {
  BookingService,
  CategoryService,
  FirebaseAuthenticationService,
  ServiceService,
} from '@app/core/services';
import { ServiceRequestService } from '@app/core/services/backend/service-request.service';
import { BookingRepository, ServiceRequestsRepository } from '@app/store';

export const loadDataGuard: CanMatchFn = async (
  route: Route,
  segments: UrlSegment[],
) => {
  const bookingRepo = inject(BookingRepository);
  const bookingService = inject(BookingService);
  const serviceRequestRepo = inject(ServiceRequestsRepository);
  const serviceService = inject(ServiceService);
  const serviceRequestService = inject(ServiceRequestService);
  const categoryService = inject(CategoryService);
  const firebaseAuthenticationService = inject(FirebaseAuthenticationService);
  const router = inject(Router);

  try {
    const user = await firebaseAuthenticationService.getCurrentUser();
    if (!user) return false;

    await firstValueFrom(
      bookingRepo.activeBooking$.pipe(
        switchMap(booking => {
          if (booking) return [booking];
          return bookingService.getAll();
        }),
        switchMap(bookings => {
          const booking = Array.isArray(bookings) ? bookings[0] : bookings;
          if (!booking) {
            router.navigate(['/no-bookings']);
            return [];
          }
          return [
            serviceService.getAllByProperty(booking.property._id),
            serviceRequestService
              .getAll()
              .pipe(
                tap(() => serviceRequestRepo.upcomingReservations(booking._id)),
              ),
            categoryService.getAll(),
          ];
        }),
        catchError(error => {
          console.error('Error in loadDataGuard:', error);
          return [];
        }),
      ),
    );

    return true;
  } catch (error) {
    console.error('Error in loadDataGuard:', error);
    return false;
  }
};
