import { AsyncPipe, CommonModule, CurrencyPipe, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DividerComponent } from './components/divider/divider.component';
import { ParallaxHeaderComponent } from './components/parallax-header/parallax-header.component';
import {
  ConvertToNumberPipe,
  LetDirective,
  ParallaxDirective,
} from './directives';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  NgIf,
  AsyncPipe,
  LetDirective,
  DividerComponent,
  ConvertToNumberPipe,
  ParallaxDirective,
  ParallaxHeaderComponent,
  CurrencyPipe,
];

@NgModule({
  imports: [...MODULES],
  declarations: [],
  exports: [...MODULES],
})
export class SharedModule {}
