import { Injectable } from '@angular/core';
import { SettingsRepository } from '@app/store';

@Injectable({
  providedIn: 'root',
})
export class OnBoardingService {
  public readonly showOnBoarding$ = this.settingsRepository.showOnBoarding$;

  private initialized = false;

  constructor(private readonly settingsRepository: SettingsRepository) {}

  public initialize(): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.settingsRepository.initialized$.subscribe(() => {
      const showOnBoarding = this.getShowOnBoarding();
      this.setShowOnBoarding(showOnBoarding);
    });
  }

  public setShowOnBoarding(showOnBoarding: boolean): void {
    this.settingsRepository.setShowOnBoarding(showOnBoarding);
  }

  public getShowOnBoarding(): boolean {
    return this.settingsRepository.getShowOnBoarding();
  }
}
