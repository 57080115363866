import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { HttpClient } from '@angular/common/http';
import { Intercom } from '@capacitor-community/intercom';
import { environment } from '@env/environment';
import {
  Intercom as IntercomWeb,
  show,
  showNewMessage,
  shutdown,
  trackEvent,
  update,
} from '@intercom/messenger-js-sdk';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private isNative: boolean;

  constructor(private _http: HttpClient) {
    this.isNative = Capacitor.isNativePlatform();
  }

  initialize(user: any) {
    if (this.isNative) {
      // Native platform (iOS/Android)
      Intercom.registerIdentifiedUser({ email: user.email });
    } else {
      this.getIntercomIdentity().subscribe(identity => {
        IntercomWeb({
          app_id: 'qkh88dfd',
          name: user?.displayName ? user?.displayName : '', // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
          email: identity.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
          user_hash: identity.hash,
          created_at: user?.metadata.creationTime, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
          hide_default_launcher: true,
        });

        if (environment.production) {
          setTimeout(() => {
            update({
              app_version:
                environment.version.major +
                '.' +
                environment.version.minor +
                '.' +
                environment.version.patch,
            });
          }, 500);
        }
      });
    }
  }

  newMessage() {
    if (Capacitor.isNativePlatform()) {
      Intercom.displayMessageComposer({ message: '' });
    } else {
      showNewMessage('');
    }
  }

  displayMessenger() {
    if (Capacitor.isNativePlatform()) {
      Intercom.displayMessenger();
    } else {
      show();
    }
  }

  logout() {
    if (Capacitor.isNativePlatform()) {
      Intercom.logout();
    } else {
      shutdown();
    }
  }

  updateUser(user: { email?: string; name?: string }) {
    if (Capacitor.isNativePlatform()) {
      Intercom.updateUser(user);
    } else {
      update(user);
    }
  }

  trackEvent(eventName: string, metaData?: any) {
    if (this.isNative) {
      Intercom.logEvent({ name: eventName, data: metaData });
    } else {
      trackEvent(eventName, metaData);
    }
  }

  private getIntercomIdentity(): Observable<{ email: string; hash: string }> {
    return this._http.get<{ email: string; hash: string }>(
      `${environment.api.serverUrl}/intercom/identity-hash`,
    );
  }
}
