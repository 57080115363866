import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { IonToolbar } from '@ionic/angular/standalone';
import { ReservationStatusComponent } from '../reservation-status/reservation-status.component';

@Component({
  selector: 'app-parallax-header',
  standalone: true,
  imports: [IonToolbar, CommonModule, ReservationStatusComponent],
  templateUrl: './parallax-header.component.html',
  styleUrls: ['./parallax-header.component.scss'],
})
export class ParallaxHeaderComponent implements AfterViewInit {
  @Input() scrollTop: number = 0;
  @Input() maxHeight: number = 300;
  @Input() cover?: string = '';

  @ViewChild(IonToolbar, { static: false }) ionToolbar!: IonToolbar & {
    el: HTMLIonToolbarElement;
  };

  private toolbarContainer!: HTMLDivElement;

  public maxBorderRadius: number = 40;
  public minBorderRadius: number = 0;
  public borderRadius: number = 40;

  public minHeight: number = 44;
  public containerHeight: number = 300;

  public opacity: number = 1;

  constructor(private _renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.ionToolbar) {
      console.error('A <ion-toolbar> element is needed inside <ion-header>');
      return false;
    } else {
      setTimeout(() => {
        this.toolbarContainer = this.ionToolbar?.el?.shadowRoot?.querySelector(
          '.toolbar-container',
        ) as HTMLDivElement;

        if (this.toolbarContainer) {
          console.log('container', this.toolbarContainer);

          this._renderer.setStyle(
            this.toolbarContainer,
            'align-items',
            `flex-start`,
          );
          this._renderer.setStyle(
            this.toolbarContainer,
            'height',
            this.maxHeight + 'px',
          );
        } else {
          console.error('The .toolbar-container element was not found.');
        }
      }, 100); // Adjust the timeout as necessary
      return true;
    }
  }

  ngOnChanges() {
    this.applyParallaxEffect(this.scrollTop);
  }

  private applyParallaxEffect(scrollTop: number) {
    const toolbar = document.getElementById('parallax-toolbar');
    const cover = document.getElementById('parallax-cover');
    if (toolbar) {
      let newHeight = this.maxHeight - scrollTop;

      if (newHeight < this.minHeight) {
        newHeight = this.minHeight;
      } else if (newHeight > this.maxHeight) {
        newHeight = this.maxHeight;
      }

      if (this.toolbarContainer) {
        this._renderer.setStyle(
          this.toolbarContainer,
          'height',
          `${newHeight}px`,
        );
      }

      this._renderer.setStyle(cover, 'height', `${newHeight}px`);
      this._renderer.setStyle(
        cover,
        'opacity',
        `${Math.max(0, 1 - this.scrollTop / 250)}`,
      );
      this._renderer.setStyle(toolbar, 'height', `${newHeight}px`);
      this._renderer.setStyle(toolbar, 'transition', 'height 0.1s ease-out');
      this._renderer.setStyle(cover, 'transition', 'height 0.1s ease-out');
    }

    this.setBorderRadius();
  }

  // Border radius
  setBorderRadius() {
    const borderRadius = Math.max(
      this.minBorderRadius,
      this.maxBorderRadius - this.scrollTop,
    );

    const toolbar = document.getElementById('parallax-toolbar');
    this._renderer.setStyle(
      toolbar,
      '-webkit-border-bottom-right-radius',
      `${borderRadius}px`,
    );
    this._renderer.setStyle(
      toolbar,
      '-webkit-border-bottom-left-radius',
      `${borderRadius}px`,
    );
    this._renderer.setStyle(
      toolbar,
      '-moz-border-radius-bottomright',
      `${borderRadius}px`,
    );
    this._renderer.setStyle(
      toolbar,
      '-moz-border-radius-bottomleft',
      `${borderRadius}px`,
    );
    this._renderer.setStyle(
      toolbar,
      'border-bottom-right-radius',
      `${borderRadius}px`,
    );
    this._renderer.setStyle(
      toolbar,
      'border-bottom-left-radius',
      `${borderRadius}px`,
    );
  }
}
