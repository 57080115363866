import { Injectable } from '@angular/core';
import { WeatherModel } from '@app/core/models';

import { createStore, select, setProps, withProps } from '@ngneat/elf';

export interface WeatherProps {
  weather: WeatherModel | null;
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'weather' },
  withProps<WeatherProps>({
    weather: null,
    meta: { lastRefresh: null },
  }),
);

@Injectable({ providedIn: 'root' })
export class WeatherRepository {
  constructor() {}

  weather$ = store.pipe(select(state => state.weather));

  setWeather(weather: WeatherModel) {
    store.update(
      setProps(state => ({
        weather,
      })),
    );
  }
}
