import { Injectable } from '@angular/core';
import { CalendarEvent, ServiceRequestModel } from '@app/core/models';

import { createStore, setProps, withProps } from '@ngneat/elf';
import {
  selectAllEntities,
  selectEntity,
  selectManyByPredicate,
  updateEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { joinRequestResult } from '@ngneat/elf-requests';
import { endOfDay, startOfDay } from 'date-fns';
import { Observable, map, tap } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ServiceRequestsProps {
  meta: {
    lastRefresh: Date | null;
  };
}

const store = createStore(
  { name: 'serviceRequests' },
  withProps<ServiceRequestsProps>({
    meta: { lastRefresh: null },
  }),
  withEntities<ServiceRequestModel, '_id'>({ idKey: '_id' }),
);

@Injectable({ providedIn: 'root' })
export class ServiceRequestsRepository {
  public serviceRequests$ = store.pipe(
    selectAllEntities(),
    joinRequestResult(['serviceRequests']),
    tap(serviceRequests => {
      console.log('service requests', serviceRequests);
    }),
  );

  public getById(_id: string) {
    return store.pipe(selectEntity(_id));
  }

  public calendarEvents$: Observable<CalendarEvent[]> = store.pipe(
    selectAllEntities(),
    map(serviceRequests => {
      if (serviceRequests && serviceRequests.length > 0) {
        return serviceRequests.map(serviceRequest => ({
          title: serviceRequest.service.name,
          start: serviceRequest.date.from,
          end: serviceRequest.date.to,
          className: serviceRequest.status,
          allDay: serviceRequest.service.serviceType === 'range' ? true : false,
          extendedProps: {
            serviceRequest,
          },
        }));
      } else {
        return [];
      }
    }),
  );

  public upcomingReservations(
    bookingId: string,
  ): Observable<ServiceRequestModel[]> {
    return store.pipe(
      selectManyByPredicate(
        serviceRequest =>
          serviceRequest.booking.externalId === bookingId &&
          serviceRequest.status !== 'canceled' &&
          new Date(serviceRequest.date.from) >= new Date(),
      ),
      map(serviceRequests =>
        serviceRequests.sort(
          (a, b) =>
            new Date(a.date.from).getTime() - new Date(b.date.from).getTime(),
        ),
      ),
    );
  }

  public getByDate(date: Date): Observable<ServiceRequestModel[]> {
    return store.pipe(
      selectManyByPredicate(
        serviceRequest =>
          new Date(serviceRequest.date.from) <= endOfDay(date) &&
          new Date(serviceRequest.date.from) >= startOfDay(date) &&
          serviceRequest.status !== 'canceled',
      ),
      map(serviceRequests =>
        serviceRequests.sort(
          (a, b) =>
            new Date(a.date.from).getTime() - new Date(b.date.from).getTime(),
        ),
      ),
    );
  }

  public createServiceRequests(serviceRequest: ServiceRequestModel) {
    if (serviceRequest) {
      store.update(upsertEntities(serviceRequest));
    }
  }

  public updateServiceRequests(serviceRequests: ServiceRequestModel[]) {
    store.update(
      upsertEntities(serviceRequests),
      setProps({ meta: { lastRefresh: new Date() } }),
    );
  }

  public updateServiceRequest(serviceRequest: ServiceRequestModel): void {
    store.update(updateEntities(serviceRequest._id, serviceRequest));
  }
}
