import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@env/environment';
import { FirebaseApp, initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAppService {
  private firebaseApp: FirebaseApp | undefined;
  private firebasDatabaseApp: FirebaseApp | undefined;

  constructor() {}

  public initialize(): void {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    if (this.firebaseApp && this.firebasDatabaseApp) {
      return;
    }
    if (!this.firebaseApp) {
      this.firebaseApp = initializeApp(environment.firebase);
    }
    if (!this.firebasDatabaseApp) {
      this.firebasDatabaseApp = initializeApp(environment.firebaseDatabase, 'backoffice');
    }
  }

  public getFirebaseApp(): FirebaseApp | undefined {
    return this.firebaseApp;
  }

  public getFirebaseDatabaseApp(): FirebaseApp | undefined {
    return this.firebasDatabaseApp;
  }
}
