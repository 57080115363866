<ion-toolbar color="primary" id="parallax-toolbar">
  <section
    id="parallax-cover"
    class="background-image"
    [ngStyle]="{
      backgroundImage: 'url(' + cover + ')'
    }"
  ></section>
  <ng-content></ng-content>
</ion-toolbar>
