import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { DialogService } from '../../dialog/dialog.service';
import { ErrorParserService } from '../error-parser-service/error-parser-service.service';
import { RouterService } from '../../router/router.service';
import { Router, RouterStateSnapshot } from '@angular/router';

const LOGTAG = '[ErrorHandlerService]';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private readonly injector: Injector,
    private readonly router: RouterService,
    private ngZone: NgZone,
    private readonly _router: Router,
  ) {}

  public handleError(error: unknown): void {
    void this.handle(error);
  }

  private async handle(error: unknown): Promise<void> {
    try {
      console.error(error);
      const errorParserService: ErrorParserService =
        this.injector.get<ErrorParserService>(ErrorParserService);
      const message = errorParserService.getMessageFromUnknownError(error);
      if (((error as any).status === 500 ||
      (error as any).status === 0 ||
      (error as any).message === 'Failed to fetch' ||
      (error as any).message.includes('Observable'))
      && this._router.url !== '/error') {
        this.ngZone.run(() => {
          this.router.navigateToErrorPage({ replaceUrl: true });
        });
      } else {
        if (this._router.url !== '/error') {
          await this.presentErrorAlert(message);
        }
      }
    } catch (errorHandlerError) {
      console.error(`${LOGTAG} Internal exception:`, errorHandlerError);
    }
  }

  private async presentErrorAlert(message: string): Promise<void> {
    const dialogService: DialogService =
      this.injector.get<DialogService>(DialogService);
    await dialogService.presentErrorAlert({ message });
  }
}
