import { Injectable } from '@angular/core';
import { SettingsRepository } from '@app/store';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  public readonly soundEnabled$ = this.settingsRepository.soundEnabled$;

  constructor(private readonly settingsRepository: SettingsRepository) {
    settingsRepository.initialized$.subscribe(() => {
      const soundEnabled = this.getSoundEnabled();
      if (soundEnabled) {
        this.setSoundEnabled(soundEnabled);
      }
    });
  }

  public setSoundEnabled(soundEnabled: boolean): void {
    this.settingsRepository.setSoundEnabled(soundEnabled);
  }

  public toggleSoundEnabled(): void {
    this.settingsRepository.setSoundEnabled(!this.getSoundEnabled());
  }

  public getSoundEnabled(): boolean | undefined {
    return this.settingsRepository.getSoundEnabled();
  }
}
