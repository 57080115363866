import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import {
  provideTransloco,
  TranslocoModule as TranslocoBaseModule,
} from '@jsverse/transloco';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { provideTranslocoPersistTranslations } from '@jsverse/transloco-persist-translations';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  exports: [TranslocoBaseModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'es'],
        defaultLang:
          typeof window !== 'undefined'
            ? window.navigator.language.split('-')[0]
            : 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: true,
        },
        fallbackLang: 'en',
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: { useValue: localStorage },
    }),
    provideTranslocoPersistTranslations({
      loader: TranslocoHttpLoader,
      storage: { useValue: localStorage },
    }),
  ],
})
export class TranslocoModule {}
