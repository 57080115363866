import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NonWorkingDayModel } from '@app/core/models/non-working-day.model';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Service responsible for handling authentication-related functionality.
 */
@Injectable()
export class NonWorkingDayService {
  constructor(
    private _http: HttpClient,
  ) {}

  getNonWorkingDayByService(serviceId: string): Observable<NonWorkingDayModel[]> {
    return this._http.get<NonWorkingDayModel[]>(
      `${environment.api.serverUrl}/non-working-days/service/${serviceId}`,
    );
  }
}
